.coming-soon {
  /* height: 100vh; */
  /* width: 100%; */
  object-fit: contain;
  object-position: center;
}
.coming-soon-mob {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: none;
}


@media screen and (max-width: 1335px) {
  .coming-soon {
    display: none;
  }
  .coming-soon-mob {
    display: block;
  }
}
@font-face {
  font-family: "Epilogue-Black";
  src: url("assets/fonts/Epilogue/Epilogue-Black-900.ttf");
}
@font-face {
  font-family: "Epilogue-Regular";
  src: url("assets/fonts/Epilogue/Epilogue-Regular-400.ttf");
}
@font-face {
  font-family: "Epilogue-Medium";
  src: url("assets/fonts/Epilogue/Epilogue-Medium-500.ttf");
}
@font-face {
  font-family: "Epilogue-Light";
  src: url("assets/fonts/Epilogue/Epilogue-Light-300.ttf");
}
@font-face {
  font-family: "Epilogue-Bold";
  src: url("assets/fonts/Epilogue/Epilogue-Bold-700.ttf");
}

@font-face {
  font-family: "Raleway-Italic";
  src: url("assets/fonts/Railway/Raleway-Italic-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Raleway-Variable";
  src: url("assets/fonts/Railway/Raleway-VariableFont_wght.ttf")
}

.main_heading {
  font-size: 42px;
  font-family: "Raleway-Variable";
  font-weight: 800;
  text-align: center;
}
.display-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.body{
  padding-left: 120px;
  padding-right: 120px;
}
@media screen and (max-width: 780px) {
  .body {
      padding-left: 0px;
      padding-right: 0px;
  }
}
.content_heading {
  font-size: 32px;
  text-align: left;
  font-family: "Raleway-Variable";
  font-weight: 700;
  margin-block-end: 0px !important;
}
.content_sub_heading {
  font-size: 24px;
  font-family: "Raleway-Variable";
  font-weight: 600;
  margin-block-end: 0px !important;
  margin-top: 20px;
}
.content {
  font-size: 18px;
  font-family: "Raleway-Variable";
  text-align: left;
  /* margin-left: 20px; */
  line-height: 30px;
  margin-top: 30px;
}
.homepage-background{
  background-image: url("../src/assets/images/landing-page.jpg");
  background-size: cover;
  background-position: center;
  height: 808vh; /* Set height as needed */
  width: 100%; /* Set width as needed */
}
.Raleway-Italic {
  font-family: "Raleway-Italic" !important;
}

.Raleway-Variable {
  font-family: "Raleway-Variable" !important;
}

.about-us-bottom{
  background-image: url("assets/images/FrankIcons/frank-comment-ca-marche-bg.png");
  background-size: cover;
  background-position: center;
  height: 10%; 
  width: 100%; /* Set width as needed */
  margin-top: 20px;
}

h6, .MuiTypography-body2 {
  color: black;
}

.MuiCard-root {
  background-color: #FFD585;
  text-align: center;
}

.MuiTypography-h4 {
  color: white;
}
.about-footer{
  font-family: 'Raleway', Arial, sans-serif;
  background-color: #1A1AB2;
  color: white;
  margin: 0;
  padding: 0;
}
img {
  pointer-events: none;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}


