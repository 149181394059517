* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "Raleway-Italic";
  src: url("../../assets/fonts/Railway/Raleway-Italic-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Raleway-Variable";
  src: url("../../assets/fonts/Railway/Raleway-VariableFont_wght.ttf");
}

:root {
  --dark-grey: #333333;
  --medium-grey: #636363;
  --light-grey: #eeeeee;
  --ash: #f4f4f4;
  --primary-color: #ee6931;
  --white: white;
  --border: 1px solid #dddddd;
}

/* body {
  background: var(--ash);
} */

#nav-menu {
  background: var(--primary-color);
  padding: 10px 0;
  z-index: 100;
  position: sticky;
  top: 0;
  width: 100%;
}

.particlular-professional {
  background-image: linear-gradient(transparent, #3026f1);
  background: url("../../assets/images/FrankIcons/particlular.jpg");
}

.main-div {
  height: calc(100vh + 190px);
  padding: 0px 40px;
  display: flex;
  align-items: center;
}

.home-banner-inner-div {
  padding: 0px 200px;
  transform: translateY(-100px);
}
.home-banner-inner-text {
  font-family: "Raleway-Italic";
  font-weight: 900;
  font-size: 58px;
  color: #fff;
}
.home-banner-btn {
  height: "54px";
}
.mob-nav-end {
  margin-left: 70px;
}
.mob-nav {
  display: none;
}

@media screen and (max-width: 800px) {
  .mob-nav {
    display: flex;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .mob-nav-end {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .main-div {
    height: calc(92vh + 20px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .home-banner-inner-div {
    padding: 0px 120px;
    transform: translateY(-90px);
  }

  .home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 52px;
    color: #fff;
  }
}

@media screen and (max-width: 1200px) {
  .main-div {
    height: calc(84vh + -33px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .home-banner-inner-div {
    padding: 0px 50px;
    transform: translateY(-90px);
  }
  .home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 46px;
    color: #fff;
  }
}

@media screen and (max-width: 1100px) {
  .main-div {
    height: calc(82vh + -33px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .home-banner-inner-div {
    padding: 0px 50px;
    transform: translateY(-90px);
  }
  .home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 42px;
    color: #fff;
  }
}

@media screen and (max-width: 980px) {
  .main-div {
    height: calc(79vh + -33px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .home-banner-inner-div {
    padding: 0px 50px;
    transform: translateY(-90px);
  }
  .home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 38px;
    color: #fff;
  }
}

@media screen and (max-width: 880px) {
  .main-div {
    height: calc(65vh + -0px);
    padding: 0px 0px;
    display: flex;
    align-items: center;
  }
  .home-banner-inner-div {
    padding: 0px 50px;
    transform: translateY(-110px);
  }
  .home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 28px;
    color: #fff;
  }
}

.container {
  background: #ee6931;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
}

.image-container-one {
  max-width: 550px;
  margin: 20px auto;
  background-color: white;
  border-radius: 32px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 550px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 300px;
}

.image-container-bottom {
  position: relative;
  width: 100%;
}

.background-image-two {
  background-image: url("../../assets/images/FrankIcons/mobile-screens-steps.png");
  background-size: cover;
  background-position: center;
  height: 100%; /* Set height as needed */
  width: 100%; /* Set width as needed */
}
.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-image-bottom {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 30px 30px 0px 0px;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, #3026f1, rgba(0, 0, 0, 0));
  padding: 10px;
  text-align: center;
}

.overlay-without-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.gradient-overlay h2 {
  margin: 0;
  color: white;
}
.image-container-one-for-bottom {
  width: 380px;
  /* margin: 20px auto; */
  background-color: white;
  border-radius: 32px;
  /* overflow: hidden; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 400px;
}

.text-container {
  padding: 30px;
  text-align: left;
  font-family: "Raleway-Variable";
  padding-top: 20px;
}

.text-container-bottom {
  padding: 10px;
  font-family: "Raleway-Variable";
}

.text-container p {
  margin: 0 0 10px;
  color: #333;
  font-family: "Raleway-Variable";
}
.logo img {
  width: 35px;
  height: 35px;
}

.menu {
  display: flex;
  align-items: center;
}

.menu-bar {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.menu-bar li {
  margin: 0 15px;
  position: relative;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  position: relative;
  font-family: "Raleway-Italic";
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-grey);
}

.dropdown-btn i {
  margin-left: 5px;
}

/* .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--white);
  border: var(--border);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 200px;
} */

.dropdown.active {
  display: block;
}

.dropdown ul {
  list-style: none;
  padding: 10px;
}

.dropdown li {
  padding: 10px;
}

.dropdown-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--dark-grey);
}

.dropdown-link img.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dropdown-link-title {
  font-weight: 600;
  color: var(--dark-grey);
}

.right-container {
  display: flex;
  align-items: center;
}

.search {
  position: relative;
}

.search input[type="search"] {
  border: var(--border);
  padding: 5px 10px;
  font-family: "Raleway-Italic";
  width: 300px;
  border-radius: 5px;
}

.search i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--medium-grey);
  font-family: "Raleway-Italic";
}

.btn {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background: var(--primary-color);
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
  font-family: "Raleway-Italic";
}

#hamburger {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

#hamburger i {
  font-size: 24px;
  color: var(--dark-grey);
}

.homepage-banner-background {
  background-image: url("../../assets/images/FrankIcons/home-banner.png");
  background-size: cover;
  background-position: center;
  height: 100%; /* Set height as needed */
  width: 100%; /* Set width as needed */
  margin-top: 10px;
}

.Raleway-Italic {
  font-family: "Raleway-Italic" !important;
}

.Raleway-Variable {
  font-family: "Raleway-Variable" !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .menu-bar {
    display: none;
  }

  .menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    /* background: var(--white); */
    width: 100%;
    /* border: var(--border); */
    padding: 10px;
  }

  .menu-bar {
    flex-direction: column;
    /* width: 100%; */
  }

  .menu-bar li {
    margin: 10px 0;
  }

  #hamburger {
    display: block;
  }
}

.web-screen {
  display: none;
}
.mobile-screen {
  display: flex;
}

@media screen and (min-width: 480px) {
  .web-screen {
    display: flex;
    flex-direction: column;
  }
  .mobile-screen {
    display: none;
  }
}

.font_resp {
  font-size: 1.5vw;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login_area {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login_area {
    display: none;
  }
  .hide_login_drawer {
    display: flex;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login_area {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login_area {
    display: flex;
    gap: 8;
  }
  .hide_login_drawer {
    display: none;
  }
}
