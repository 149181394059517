.font_resp {
  font-size: 1vw !important;
  font-weight: 600 !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login_area {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login_area {
    display: none;
  }
  .hide_login_drawer {
    display: flex;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login_area {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login_area {
    display: flex;
    gap: 8;
  }
  .hide_login_drawer {
    display: none;
  }
}

.left {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  /* gap: 60px; */
  position: relative;
  /* width: 1000px; */
  z-index: 2;
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 870px;
  z-index: 4;
}

.menu-component {
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-wrap: nowrap; */
  /* flex-shrink: 0; */
  /* gap: 4px; */
  /* width: 110px; */
  z-index: 5;
  position: relative;
  /* display: inline-block; */
}

.particulier {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  /* height: 19px; */
  color: #080626;
  font-family: "Raleway-Italic";
  /* font-size: 15px; */
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
  display: inline-block;
}
.particulier:hover {
  color: #3026f1;
}

.dropdown {
  display: none;
  position: absolute;
  /* width: 200%; */
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 11111;
  border-radius: 4px;
  padding: 8px 0;
  border-radius: 20px;
  padding: 20px 10px 20px 10px;
  width: 350px;
  /* margin-top:5px ; */
}

.menu-component:hover .dropdown {
  display: block;
  z-index: 11111;
}

.dropdown-item {
  padding: 15px 16px;
  cursor: pointer;
  white-space: nowrap;
  font-family: "Raleway-Variable";
  font-weight: 500;
  z-index: 11111;
}

.dropdown-item:hover {
  background-color: #fce6dd;
  border-radius: 10px;
  color: #ee6931;
  z-index: 11111;
}